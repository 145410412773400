import React from 'react';
import Layout from '@common/Layout';
import NavbarSubpages from '@common/Navbar/NavbarSubpages';
import WoannaPrivacyPolicy from '../../components/sections/WoannaPrivacyPolicy_en';
import Footer from '../../components/sections/Footer';
import SEO from '@common/SEO';

const ThirdPage = () => (
  <Layout>
    <SEO/>
    <NavbarSubpages />
    <Layout>
    <WoannaPrivacyPolicy />
    </Layout>
    <Footer />
  </Layout>
);

export default ThirdPage;